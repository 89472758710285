<template>
  <ion-page style="margin:1rem">
            <ion-router-outlet></ion-router-outlet>
  </ion-page>
</template>

<script lang="ts">
  import {
    IonPage,
    IonRouterOutlet,
  } from '@ionic/vue';
  import { defineComponent } from 'vue'; 

  export default defineComponent({
    components: {
      IonPage,
      IonRouterOutlet,
    },
  });
</script>