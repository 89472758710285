<template>
    <ion-page>
            <ion-header :translucent="true">
            <ion-toolbar>
              <ion-buttons slot="start"  v-show=true>
                <ion-menu-button color="primary"></ion-menu-button>
              </ion-buttons>
              <ion-title>{{ $route.name }}</ion-title>
            </ion-toolbar>
          </ion-header>
      <ion-tabs>
          <SidebarMenu v-if="userAuth().can('view sidebar')"></SidebarMenu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
        
         <p v-if="currentUserStore().$state.user" class="ion-text-center" style="font-size: small;">
          Hola {{ currentUserStore().$state.user?.name.split(' ')[0] }}
          <ion-icon size="small" :icon="logOutOutline" @click="userAuth().logout()" style="margin-left: 0.4rem;" />
        </p>
      </ion-tabs>

    </ion-page>
  </template>
  
  <script lang="ts">
    import { IonToolbar,IonPage, IonTabs, IonRouterOutlet, IonIcon,IonTitle,IonHeader,IonMenuButton,IonButtons } from '@ionic/vue';
    import { homeOutline, walletOutline, peopleCircleOutline,logoWhatsapp, logOutOutline } from 'ionicons/icons';
    import SidebarMenu from '@/components/SidebarMenu.vue'
    import userAuth from '@/composables/userAuth';
    import { currentUserStore } from '@/stores/currentUser';


    export default {
      components: { IonToolbar,SidebarMenu,IonPage, IonTabs, IonRouterOutlet, IonIcon,IonTitle,IonHeader,IonMenuButton,IonButtons },
      setup() {


        return {
          walletOutline,
          peopleCircleOutline,
          homeOutline,
          userAuth,
          logoWhatsapp,
          currentUserStore,
          logOutOutline
        };       
      },
    };
  </script>

<style scoped>

  ion-label{
    font-size: 0.8rem;
  }

  ion-tab-bar{
    height: 4rem;
  }

</style>