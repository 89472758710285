import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb6d77ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "ion-text-center",
  style: {"font-size":"small"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { color: "primary" })
                ]),
                _: 1
              }, 512), [
                [_vShow, true]
              ]),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$route.name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          ($setup.userAuth().can('view sidebar'))
            ? (_openBlock(), _createBlock(_component_SidebarMenu, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_router_outlet, { id: "main-content" }),
          ($setup.currentUserStore().$state.user)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                _createTextVNode(" Hola " + _toDisplayString($setup.currentUserStore().$state.user?.name.split(' ')[0]) + " ", 1),
                _createVNode(_component_ion_icon, {
                  size: "small",
                  icon: $setup.logOutOutline,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.userAuth().logout())),
                  style: {"margin-left":"0.4rem"}
                }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}