import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import tools from "@/composables/tools";


import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';


import { currentUserStore } from '@/stores/currentUser'
import { User } from '@/interfaces/User';

const pinia = createPinia()


const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(VueAxios,axios)
  .use(router);
 
  moment.locale('es');
 
  app.config.globalProperties.$filters = {
    timeAgo(date:string) {
      return moment(date).fromNow()
    },
    human(date:string) {
      let val = moment(date).format('dddd D')
      val += ' de '
      val += moment(date).format('MMMM')
      return val
    },
  }
  

router.isReady().then(() => {
  app.mount('#app');
});

router.beforeEach((to) => {
  if(to.meta.requiredAuth && localStorage.getItem('user')==undefined)
  {
      currentUserStore().$state.redirectAfterLogin = to.fullPath
      router.push({path: "/login"});
  }
  return true
})


axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL
axios.defaults.headers.common["Accept"] = "application/json"
if(localStorage.getItem("token"))
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

//hacemos un interceptor que derive a login cada vez que haya un error 401 no autenticado
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      console.log(error.response)
      tools().toast(error.response.data.message,'danger')
      router.push({ path: "/login" });
    }
    else{
      tools().toast(error.response.data.message,'danger',10000)
    }
    return Promise.reject(error);
  });  

//** guardamo como state los datos del usuario logeado */
const userJson = localStorage.getItem('user');
const currentUserObj:User = userJson !== null ? JSON.parse(userJson):{}
const currentUser = currentUserStore()
currentUser.update(currentUserObj)
